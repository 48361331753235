<template>
  <div class="company_list_item">
    <div class="left">
      <div class="img-wrap">
        <el-image
          style="width: 100%; height: 100%; background: #f2f2f2"
          :src="companyInfo.logo ? companyInfo.logo : defaultLogo"
          fit="scale-down"
        ></el-image>
      </div>
    </div>
    <div class="right">
      <div class="companyTitle clearfix">
        <div class="company fl">
          <span class="name" @click="goToDetail">
            {{ companyInfo.name_en | priorFormat(companyInfo.name_zh, LOCALE) }}
          </span>
          <el-popover
            placement="top-start"
            title="会员到期时间"
            width="200"
            trigger="hover"
            :content="companyInfo.end_time | secondFormat('LL')"
          >
            <span
              class="icon"
              slot="reference"
              v-if="companyInfo.genre_ids.length > 0"
            >
              <el-image
                style="width: 29px; height: 28px"
                :src="url"
                fit="scale-down"
              ></el-image>
            </span>
          </el-popover>
        </div>
        <div class="btns fl">
          <el-button
            @click="favorites"
            type="danger"
            size="small"
            v-if="companyInfo.siffa_collection_id === 0"
          >
            <el-image
              class="hearticon"
              style="width: 10px; height: 10px"
              :src="heart"
              fit="scale-down"
            ></el-image>
            收藏
          </el-button>
          <el-button
            @click="unfavorite"
            class="isselect"
            type="info"
            plain
            size="small"
            v-if="companyInfo.siffa_collection_id"
            >已收藏</el-button
          >
        </div>
      </div>
      <div class="rating">
        <span
          class="text1 mr24"
          v-if="companyInfo.genre_ids && companyInfo.genre_ids.length"
        >
          <span v-for="(item, index) in companyInfo.genre_ids" :key="index">
            {{ Number(item) | unitTypeFormat(LOCALE) | textFormat }}
          </span>
        </span>
        <span class="ratingLevel" v-if="companyInfo.company_credit == 1">
          信用评级AAA
        </span>
        <span class="ratingLevel" v-else-if="companyInfo.company_credit == 2">
          信用评级AA
        </span>
        <span class="ratingLevel" v-else-if="companyInfo.company_credit == 3">
          信用评级A
        </span>
      </div>
      <div class="business">
        <span class="text1 mr24"> 优势业务 </span>
        <template
          v-if="
            companyInfo.business_classification_list &&
            companyInfo.business_classification_list.length > 0
          "
        >
          <el-tag
            type="info"
            size="small"
            class="item"
            v-for="(item, index) in companyInfo.business_classification_list"
            :key="index"
          >
            {{ item.desc_en | priorFormat(item.desc_zh, LOCALE) }}
          </el-tag>
        </template>
        <span v-else>-</span>
      </div>
      <div class="website">
        <div class="officialWebsite textOverflow">
          <span class="text1 mr52">企业网址</span>
          <span
            class="blueColor"
            @click="goToWensite(companyInfo.website)"
            :title="companyInfo.website"
          >
            {{ companyInfo.website | textFormat }}
          </span>
        </div>
        <div class="address textOverflow">
          <span class="text1 mr52">地址</span>
          <span
            :title="
              companyInfo.address_en
                | priorFormat(companyInfo.address_zh, LOCALE)
            "
          >
            {{
              companyInfo.address_en
                | priorFormat(companyInfo.address_zh, LOCALE)
            }}
          </span>
        </div>
      </div>
      <div class="video" v-if="videoShow">
        <template v-for="(item, id) in companyInfo.video_info">
          <div
            :key="id"
            class="fl"
            style="margin-right: 18px; width: 200px; height: 151px"
            v-if="id < number"
          >
            <videoPlay ref="videoPlay" class="itemBox">
              <div class="iconDiv" v-show="item.aliyun_id">
                <i
                  @click="allplayVideo(item.aliyun_id)"
                  class="el-icon-video-play iconPlay"
                ></i>
              </div>
              <el-image
                style="width: 200px; height: 151px"
                :src="item.video_cover_url || item.cover_url"
                fit="cover"
              ></el-image>
            </videoPlay>
          </div>
        </template>
        <template v-for="(item, index) in companyInfo.picture_info">
          <div
            class="fl"
            :key="item.id"
            style="margin-right: 18px"
            v-if="companyInfo.video_info.length + index < number"
          >
            <el-image
              :fit="'contain'"
              style="width: 200px; height: 151px; background: #ccc"
              :src="item.url"
              class="coverImg"
              :preview-src-list="[item.url]"
            ></el-image>
          </div>
        </template>
        <div
          @click="goToDetail"
          v-if="
            companyInfo.video_info.length + companyInfo.picture_info.length >
            number
          "
          class="palyVideo fl"
        >
          <el-image
            style="width: 200px; height: 151px"
            :src="cover"
            class="coverImg"
          ></el-image>
          <div class="mark" style="line-height: 151px">
            <i class="ppp">查看更多...</i>
          </div>
        </div>
      </div>
    </div>
    <videoPlay ref="allvideoplay" />
  </div>
</template>

<script>
import videoThumbnail from "@/baseComponents/videoThumbnail";
import videoPlay from "@/baseComponents/videoPlay";

export default {
  name: "unitItem",
  components: {
    videoThumbnail,
    videoPlay,
  },
  props: {
    number: {
      //多媒体展示个数 企业名录2个 协会概况3个
      type: Number,
      default: 2,
    },
    companyInfo: {
      type: Object,
      default: function () {
        return {};
      },
    },
    videoShow: {
      //是否显示多媒体
      type: Boolean,
      default: true,
    },
    iconShow: {
      //1 会员图标根据会员状态判断  2.会员图标一直存在
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      companyData: {
        video_info: [],
        picture_info: [],
      },
      defaultLogo:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/companyLogo.png",
      url:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/plc/%E4%BC%9A%E5%91%98%E7%AD%89%E7%BA%A7.png",
      cover:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/websiteImg/images/c_cover.png",
      heart:
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/heart.png",
    };
  },
  methods: {
    goToWensite(url) {
      let _url;
      if (url.indexOf("http") != -1) {
        _url = url;
      } else {
        _url = `http://${url}`;
      }
      window.open(_url);
    },
    async favorites() {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.$emit("refresh");
          },
        });
        return;
      }
      let params = {};
      params.user_id = this.USER_ID;
      params.related_id = this.companyInfo.id;
      params.collection_type = 1;
      params.source = this.PJSource
      let res = await this.$store.dispatch(
        "API_company/favoritesCompany",
        params
      );
      if (res.success) {
        this.$message({
          message: "已收藏到用户中心收藏夹",
          type: "success",
        });
        this.companyInfo.siffa_collection_id = res.data;
      }
    },
    async unfavorite() {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.$emit("refresh");
          },
        });
        return;
      }
      let params = {};
      params.deleted = 1;
      params.siffa_collection_id = this.companyInfo.siffa_collection_id;
      let res = await this.$store.dispatch(
        "API_company/favoritesCompany",
        params
      );
      if (res.success) {
        this.$message({
          message: "取消收藏",
          type: "success",
        });
        this.companyInfo.siffa_collection_id = 0;
      }
    },
    allplayVideo(id) {
      if (this.IS_LOGIN) {
        this.$refs.allvideoplay.startPlay(id);
      } else {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN", {
          waitTodo: () => {
            this.$refs.allvideoplay.startPlay(id);
          },
        });
      }
    },
    goToDetail() {
      let res = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: this.companyInfo.id,
          }),
        },
      });
      window.open(res.href, "_blank");
    },
  },
};
</script>

<style scoped lang="less">
.company_list_item {
  width: 100%;
  padding: 30px 26px;
  display: flex;
  background: #ffffff;
  margin-bottom: 16px;
  box-sizing: border-box;
  .left {
    width: 120px;
    margin-right: 32px;

    .img-wrap {
      width: 100%;
      height: 120px;
    }
  }

  .right {
    width: 700px;

    .companyTitle {
      margin-bottom: 26px;
      .company {
        width: 622px;
        .name {
          font-size: 20px;
          font-weight: bold;
          color: #333333;
          margin-right: 6px;
          cursor: pointer;
        }
        .icon {
          cursor: pointer;
        }
      }

      .btns {
        width: 70px;
        /deep/ .el-button {
          background-color: #3384b1 !important;
          border: #3384b1 !important;
        }
        /deep/ .isselect {
          color: #ccd0d3 !important;
          background-color: #f5f5f7 !important;
        }
        .hearticon {
          position: relative;
          top: 1px;
        }
      }
    }

    .rating {
      margin-bottom: 20px;

      .ratingLevel {
        background: #eef9ff;
        border-radius: 13px;
        padding: 4px 17px;
        font-size: 14px;
        font-weight: 400;
        color: #3384b1;
      }
    }

    .business {
      margin-bottom: 20px;

      .item {
        margin-right: 12px;
        margin-bottom: 5px;
      }
    }

    .website {
      display: flex;
      margin-bottom: 32px;
      .officialWebsite {
        margin-right: 50px;
        max-width: 340px;

        .blueColor {
          color: #1989fa;
          cursor: pointer;
        }
      }
      .address {
        max-width: 340px;
      }
    }
    .video {
      div:nth-child(4) {
        margin-right: 0 !important;
      }
    }
  }
}

.text1 {
  font-size: 14px;
  font-weight: 800;
  color: #333333;
}

.mr24 {
  margin-right: 24px;
}

.mr52 {
  margin-right: 56px;
}
.palyVideo {
  width: 200px;
  height: 151px;
  position: relative;
  .coverImg {
    width: 200px;
    height: 151px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .mark {
    width: 200px;
    height: 151px;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    text-align: center;
    cursor: pointer;
    .el-icon-video-play {
      font-size: 34px;
      color: #aaa;
      line-height: 151px;
    }
  }
  .mark:hover {
    background: rgba(0, 0, 0, 0.6);
  }
  .mark:hover .el-icon-video-play {
    color: #fff;
  }
  .mark .ppp {
    color: #fff;
  }
}
.itemBox {
  position: relative;
  .iconDiv {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s;

    .el-icon-video-play {
      font-size: 34px;
      color: #aaa;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .iconDiv:hover {
    background: rgba(0, 0, 0, 0.6);
  }

  .iconDiv:hover .el-icon-video-play {
    color: #fff;
  }
}
</style>
